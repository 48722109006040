import React, { useEffect, useState } from "react";
import { Box, Stack, Button, Grid, Typography } from "@mui/material";
import RSelect from "react-select";
import {
  getCampaignBillboardsAPI,
  getlinegraphDataAPI,
  getBoxPlotDataAPI,
  getDayOfWeekBoxPlotDataAPI,
  getMonthlyHeatmapAPI
} from "../../apis/campaigns.apis";
import Loader from "../../components/Loader";
import SpeedDurationBoxPlot from "./SpeedDurationBoxPlot";
import HourlyVisibilityLineGraph from "./HourlyVisibilityLineGraph";
import DayOfWeekBoxPlot from "./DayOfWeekBoxPlot";
import MonthlyHeatmapPlot from "./MonthlyHeatmapPlot";
import { toast } from "react-toastify";

export default function CampaignLocationFilter({ campaignId }) {
  const [zones, setZones] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState([]);
  const [boxPlotData, setBoxPlotData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visibilityGraphData, setVisibilityGraphData] = useState(null);
  const [dayOfWeekData, setDayOfWeekData] = useState(null);
  const [monthlyheatmapdata, setmonthlyheatmapdata] = useState(null);

  const [selected, setSelected] = useState({
    zone: null,
    state: null,
    city: null,
    location: null,
  });

  useEffect(() => {
    const fetchBillboards = async () => {
      try {
        const res = await getCampaignBillboardsAPI(campaignId);
        const result = res.data;
        setData(result);
        const zoneOptions = result.map((z) => ({
          label: z.zone_name,
          value: z.zone_id,
        }));
        setZones(zoneOptions);
      } catch (err) {
        console.error("Error fetching billboards:", err);
      }
    };
    fetchBillboards();
  }, [campaignId]);

  const handleChange = (key, value) => {
    let newSelected = { ...selected, [key]: value };

    if (key === "zone") {
      const zone = data.find((z) => z.zone_id === value.value);
      const stateOptions =
        zone?.states.map((s) => ({
          label: s.state_name,
          value: s.state_id,
        })) || [];
      setStates(stateOptions);
      setCities([]);
      setLocations([]);
      newSelected = { zone: value, state: null, city: null, location: null };
    } else if (key === "state") {
      const zone = data.find((z) => z.zone_id === selected.zone?.value);
      const state = zone?.states.find((s) => s.state_id === value.value);
      const cityOptions =
        state?.cities.map((c) => ({
          label: c.city_name,
          value: c.city_id,
        })) || [];
      setCities(cityOptions);
      setLocations([]);
      newSelected = { ...selected, state: value, city: null, location: null };
    } else if (key === "city") {
      const zone = data.find((z) => z.zone_id === selected.zone?.value);
      const state = zone?.states.find(
        (s) => s.state_id === selected.state?.value
      );
      const city = state?.cities.find((c) => c.city_id === value.value);
      const locationOptions =
        city?.locations.map((l) => ({
          label: l,
          value: l,
        })) || [];
      setLocations(locationOptions);
      newSelected = { ...selected, city: value, location: null };
    } else if (key === "location") {
      newSelected = { ...selected, location: value };
    }

    setSelected(newSelected);
  };

  const handleShowDayOfWeekGraph = async () => {
    try {
      setIsLoading(true);
      const payload = {
        campaign_id: campaignId,
        zone_id: selected.zone?.value || null,
        state_id: selected.state?.value || null,
        city_id: selected.city?.value || null,
        location: selected.location?.value || null,
      };
  
      const res = await getDayOfWeekBoxPlotDataAPI(payload);
  
      if (res.success && res.data && Object.keys(res.data).length > 0) {
        setDayOfWeekData(res.data);
        setBoxPlotData(null);
        setVisibilityGraphData(null);
        setmonthlyheatmapdata(null)
        toast.success("Day of Week Graph plotted successfully!");
      } else {
        setDayOfWeekData(null);
        setBoxPlotData(null);
        setVisibilityGraphData(null);
        setmonthlyheatmapdata(null)
        toast.error("No data available to plot Day of Week Graph!");
      }
    } catch (err) {
      setDayOfWeekData(null);
      toast.error("Failed to plot Day of Week Graph!");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleShowMonthlyHeatmap = async () => {
    try {
      setIsLoading(true);
      const payload = {
        campaign_id: campaignId,
        zone_id: selected.zone?.value || null,
        state_id: selected.state?.value || null,
        city_id: selected.city?.value || null,
        location: selected.location?.value || null,
      };
  
      const res = await getMonthlyHeatmapAPI(payload);
  
      if (res.success && res.data && Object.keys(res.data).length > 0) {
        setmonthlyheatmapdata(res.data);
        setBoxPlotData(null);
        setVisibilityGraphData(null);
        setDayOfWeekData(null);
        toast.success("Monthly Heatmap Graph plotted successfully!");
      } else {
        setmonthlyheatmapdata(null);
        setBoxPlotData(null);
        setVisibilityGraphData(null);
        setDayOfWeekData(null);
        toast.error("No data available for Monthly Heatmap!");
      }
    } catch (err) {
      setmonthlyheatmapdata(null);
      setBoxPlotData(null);
      setVisibilityGraphData(null);
      setDayOfWeekData(null);
      toast.error("Failed to plot Monthly Heatmap!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowGraphs = async () => {
    setIsLoading(true);
    try {
      const payload = {
        campaign_id: campaignId,
        zone_id: selected.zone?.value || null,
        state_id: selected.state?.value || null,
        city_id: selected.city?.value || null,
        location: selected.location?.value || null,
      };
  
      const res = await getBoxPlotDataAPI(payload);
  
      if (res.success && res.data && Object.keys(res.data).length > 0) {
        setBoxPlotData(res.data);
        setVisibilityGraphData(null); // Hide visibility graph
        setDayOfWeekData(null);
        setmonthlyheatmapdata(null);
        toast.success("Graph plotted successfully!");
      } else {
        setBoxPlotData(null);
        setVisibilityGraphData(null); // Hide visibility graph
        setDayOfWeekData(null);
        setmonthlyheatmapdata(null);
        toast.error("No data available to plot box chart!");
      }
    } catch (err) {
      setBoxPlotData(null);
      setVisibilityGraphData(null); // Hide visibility graph
      setDayOfWeekData(null);
      setmonthlyheatmapdata(null);
      toast.error("Failed to plot Box chart!");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleShowVisibilityGraph = async () => {
    try {
      setIsLoading(true);
      const payload = {
        campaign_id: campaignId,
        zone_id: selected.zone?.value || null,
        state_id: selected.state?.value || null,
        city_id: selected.city?.value || null,
        location: selected.location?.value || null,
      };
  
      const res = await getlinegraphDataAPI(payload);
      console.log(res);
  
      if (res.success && res.data && Object.keys(res.data).length > 0) {
        setVisibilityGraphData(res.data);
        setBoxPlotData(null);
        setDayOfWeekData(null);
        setmonthlyheatmapdata(null);
        toast.success("Graph plotted successfully!");
      } else {
        setVisibilityGraphData(null);
        setBoxPlotData(null);
        setDayOfWeekData(null);
        setmonthlyheatmapdata(null);
        toast.error("No data available to plot the graph!");
      }
    } catch (err) {
      setVisibilityGraphData(null);
      setBoxPlotData(null);
      setDayOfWeekData(null);
      setmonthlyheatmapdata(null);
      toast.error("Failed to plot line chart!");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <RSelect
            placeholder="Zone"
            options={zones}
            value={selected.zone}
            onChange={(v) => handleChange("zone", v)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RSelect
            placeholder="State"
            options={
              states.length
                ? states
                : [{ label: "Select Zone First", value: 0 }]
            }
            value={selected.state}
            onChange={(v) => handleChange("state", v)}
            isDisabled={!selected.zone}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RSelect
            placeholder="City"
            options={
              cities.length
                ? cities
                : [{ label: "Select State First", value: 0 }]
            }
            value={selected.city}
            onChange={(v) => handleChange("city", v)}
            isDisabled={!selected.state}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <RSelect
            placeholder="Location"
            options={
              locations.length
                ? locations
                : [{ label: "Select City First", value: 0 }]
            }
            value={selected.location}
            onChange={(v) => handleChange("location", v)}
            isDisabled={!selected.city}
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={2}
      >
        <Button
          variant="contained"
          onClick={handleShowGraphs}
          disabled={!selected.zone}
        >
          Show Box Plot Graphs
        </Button>
        <Button
          variant="contained"
          onClick={handleShowVisibilityGraph}
          disabled={!selected.zone}
        >
          Show Hourly Visibility Graph
        </Button>
        <Button
          variant="contained"
          onClick={handleShowDayOfWeekGraph}
          disabled={!selected.zone}
        >
          Show Day of Week Graph
        </Button>
        <Button
          variant="contained"
          onClick={handleShowMonthlyHeatmap}
          disabled={!selected.zone}
        >
          Show Monthly Heatmap
        </Button>
      </Box>

      {boxPlotData && (
        <Box mt={4}>
          <Typography variant="h6" mb={2}>
            Speed & Duration Box Plots
          </Typography>
          <Loader open={isLoading} />
          <SpeedDurationBoxPlot data={boxPlotData} />
        </Box>
      )}

      {visibilityGraphData && (
        <Box mt={4}>
          <Typography variant="h6" mb={2}>
            Hourly Visibility Graph
          </Typography>
          <Loader open={isLoading} />
          <HourlyVisibilityLineGraph data={visibilityGraphData} />
        </Box>
      )}

      {dayOfWeekData && (
        <Box mt={4}>
          <Typography variant="h6" mb={2}>
            Day of Week Visibility Graph
          </Typography>
          <Loader open={isLoading} />
          <DayOfWeekBoxPlot data={dayOfWeekData} />
        </Box>
      )}

      {monthlyheatmapdata && (
        <Box mt={4}>
          <Typography variant="h6" mb={2}>
            Monthly Visibility Heatmap
          </Typography>
          <Loader open={isLoading} />
          <MonthlyHeatmapPlot data={monthlyheatmapdata} />
        </Box>
      )}
    </Stack>
  );
}
