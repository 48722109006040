import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

const BillboardDateSelection = ({ open, onClose, billboards = [], onConfirm }) => {
  const [billboardData, setBillboardData] = useState([]);
  const [masterStartDate, setMasterStartDate] = useState("");
  const [masterDuration, setMasterDuration] = useState("");


  const formatDate = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return "";
  
    return parsedDate.toISOString().split("T")[0]; // YYYY-MM-DD (for input)
  };

  useEffect(() => {
    setBillboardData(
      billboards.map((billboard) =>
        typeof billboard === "string"
          ? { id: billboard, startDate: "", duration: "", isNew: true, error: { startDate: false, duration: false } }
          : { 
              ...billboard, 
              startDate: formatDate(billboard.startDate), // ✅ Apply format here
              isNew: false, 
              error: { startDate: false, duration: false } 
            }
      )
    );
  }, [billboards]);

  // Handle individual changes in the table
  const handleChange = (index, field, value) => {
    setBillboardData((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [field]: field === "duration" ? Number(value) : value, error: { ...item.error, [field]: false } } : item
      )
    );
  };

  // Apply master start date & duration to all billboards
  const applyMasterValues = () => {
    setBillboardData((prev) =>
      prev.map((item) => ({
        ...item,
        startDate: masterStartDate || item.startDate,
        duration: masterDuration ? Number(masterDuration) : item.duration,
      }))
    );
  };

  // Validate input & confirm changes
  const handleConfirm = () => {
    const isValid = billboardData.every((b) => b.startDate && b.duration > 0);
    if (!isValid) {
      alert("Please enter valid start dates and durations.");
      return;
    }

    onConfirm(billboardData); // Send updated data to parent
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select Billboards & Set Details</DialogTitle>
      <DialogContent>
        {/* Master Start Date & Duration Inputs */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <TextField
              label="Master Start Date"
              type="date"
              fullWidth
              value={masterStartDate}
              onChange={(e) => setMasterStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Master Duration (Days)"
              type="number"
              fullWidth
              value={masterDuration}
              onChange={(e) => setMasterDuration(e.target.value)}
              inputProps={{ min: 1 }}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Button variant="contained" color="primary" onClick={applyMasterValues}>
              Apply to All
            </Button>
          </Grid>
        </Grid>

        {/* Billboard Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Billboard ID</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Duration (Days)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billboardData.map((billboard, index) => (
                <TableRow key={billboard.id}>
                  <TableCell>{billboard.id}</TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={billboard.startDate}
                      onChange={(e) => handleChange(index, "startDate", e.target.value)}
                      fullWidth
                      error={billboard.error.startDate}
                      helperText={billboard.error.startDate ? "Start date is required" : ""}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={billboard.duration}
                      onChange={(e) => handleChange(index, "duration", e.target.value)}
                      fullWidth
                      inputProps={{ min: 1 }}
                      error={billboard.error.duration}
                      helperText={billboard.error.duration ? "Duration must be greater than 0" : ""}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillboardDateSelection;
