import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { getMonthlyData } from "../../../apis/plans.apis";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
  Line,
  ResponsiveContainer,
  ErrorBar,
} from "recharts";

const MonthlyBoxPlot = ({ billboardId }) => {
  const [boxPlotData, setBoxPlotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getMonthlyData(billboardId);
        setBoxPlotData(data);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [billboardId]);

  return (
    <Card sx={{ p: 2, borderRadius: 2 }}>
      <Typography variant="h6" textAlign="center">
        Monthly Visibility Duration (Box Plot)
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart data={boxPlotData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis label={{ value: "Duration (Seconds)", angle: -90, position: "insideLeft" }} />
            <Tooltip />
            {/* Bar representing the interquartile range (Q1 to Q3) */}
            <Bar dataKey="q3" fill="#f39c1c" barSize={30} />
            <ErrorBar dataKey="median" width={8} stroke="black" />
            {/* Line for Median */}
            <Line type="monotone" dataKey="median" stroke="red" strokeWidth={2} dot={false} />
            {/* Min and Max error bars */}
            <ErrorBar dataKey="q1" width={4} stroke="black" direction="y" />
            <ErrorBar dataKey="max" width={4} stroke="black" direction="y" />
            <ErrorBar dataKey="min" width={4} stroke="black" direction="y" />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default MonthlyBoxPlot;
