import React from "react";
import Plot from "react-plotly.js";
import { Box, Paper, Typography, Divider, Grid } from "@mui/material";
import { getDay, startOfMonth, addDays } from "date-fns";

const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function generateCalendarMatrix(durationsMap, year, month) {
  const start = startOfMonth(new Date(year, month - 1));
  const totalDays = new Date(year, month, 0).getDate();

  const matrix = [];
  const texts = [];

  let week = new Array(7).fill(null);
  let weekText = new Array(7).fill("");

  for (let day = 1; day <= totalDays; day++) {
    const date = new Date(year, month - 1, day);
    const dayOfWeek = date.getDay(); // 0 (Sun) to 6 (Sat)

    week[dayOfWeek] = durationsMap[day] ?? 0;
    weekText[dayOfWeek] = `${day}`;

    // If it's Saturday or last day, push and reset
    if (dayOfWeek === 6 || day === totalDays) {
      matrix.push([...week]);
      texts.push([...weekText]);
      week = new Array(7).fill(null);
      weekText = new Array(7).fill("");
    }
  }

  return { z: matrix, text: texts };
}

export default function MonthlyHeatmapPlot({ data }) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  return (
    <Box mt={3}>
      {Object.entries(data).map(([billboardId, billboardData]) => {
        const { durations_by_day } = billboardData;

        const { z, text } = generateCalendarMatrix(
          durations_by_day,
          currentYear,
          currentMonth
        );

        return (
          <Paper
            key={billboardId}
            elevation={4}
            sx={{ p: 3, mb: 4, borderRadius: 3, backgroundColor: "#f9f9f9" }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: "primary.main", fontWeight: 600 }}>
              Billboard: {billboardId} - {billboardData.location} ({billboardData.height} x {billboardData.width})
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Plot
              data={[
                {
                  z,
                  x: dayLabels,
                  y: Array.from({ length: z.length }, (_, i) => `Week ${i + 1}`),
                  text,
                  type: "heatmap",
                  hovertemplate: "Day %{text}<br>Duration: %{z}<extra></extra>",
                  colorscale: "Reds",
                  showscale: true,
                },
              ]}
              layout={{
                height: 300,
                margin: { t: 30, l: 60, r: 20, b: 60 },
                paper_bgcolor: "#fff",
                plot_bgcolor: "#fff",
              }}
              useResizeHandler
              style={{ width: "100%", height: "100%" }}
              config={{ displayModeBar: false }}
            />
          </Paper>
        );
      })}
    </Box>
  );
}
